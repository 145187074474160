@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Turret+Road:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@600&family=Turret+Road:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@600&family=Space+Grotesk:wght@500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: linear-gradient(180deg, #e6eaf5 0%, #f6f6f6 80%);
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  /* font-family: "Inter var", sans-serif; */
  /* font-family: "Syne Mono", monospace; */
  /* font-family: "Turret Road", monospace; */
  cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="),
    auto;
}

h1 {
  /* font-family: "Syne Mono", monospace; */
  /* font-family: "IBM Plex Mono", monospace; */
  line-height: 1.15;
  font-family: "Space Grotesk", sans-serif;
  /* font-size: 3.2rem; */
}

p {
  margin: 0;
  padding: 0;
}

a {
  padding-right: 10px;
  cursor: pointer;
  pointer-events: all;
  color: black;
  text-decoration: none; /* no underline */
}

.foreground-box {
  position: absolute;
  width: auto;
  height: auto;

  pointer-events: all;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  text-align: center;

  background-color: rgba(240, 248, 255, .5);

  z-index: 1;
}

.foreground-container {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  top: 0;
  left: 0;

  pointer-events: all;

  overflow-y: scroll;
  word-break: wrap;
  scrollbar-width: none;
}

.foreground-container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.foreground-bin {
  background-color: rgba(240, 248, 255, .5);
  font-family: "Space Grotesk", sans-serif;
  cursor: auto;
}

.text-xs {
  font-family: "Space Grotesk", sans-serif;
  
}

.banner {
  font-family: "Space Grotesk", sans-serif;
  z-index: 2;
  background-color: black;
  color: #efefef;
}

.banner a {
  color: #efefef;
}

.background-box {
  position: relative;

  pointer-events: all;

  height: 100%;
  width: 100%;
}

button {
  text-align: center;
}

@media screen and (max-width: 568px) {
  .full {
    visibility: hidden;
    display: none;
  }
}

.graffiti {
  font-family: monospace;
  white-space: nowrap;
}

.graffiti-blob {
  background-color: rgba(240, 248, 255, .5);
}

.spacer {
  width: 100vw;
}